<template>
  <md-toolbar
    id="toolbar"
    md-elevation="0"
    class="md-transparent md-absolute"
    :class="extraNavClasses"
    :color-on-scroll="colorOnScroll"
  >
    <div class="md-toolbar-row md-collapse-lateral">
      <div class="md-toolbar-section-start">
        <a href="/">
          <span class="md-title">
            Accueil
          </span>
        </a>
      </div>
      <div class="md-toolbar-section-end">
        <md-button
          class="md-just-icon md-simple md-toolbar-toggle"
          :class="{toggled: toggledClass}"
          @click="toggleNavbarMobile()"
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </md-button>

        <div class="md-collapse">
          <div class="md-collapse-wrapper">
            <md-list>
              
              <md-list-item href="/services/">
                <i class="material-icons">spa</i>
                <p>Séances</p>
              </md-list-item>

              <md-list-item href="/sophrologie/">
                <i class="material-icons">contact_support</i>
                <p>La sophrologie</p>
              </md-list-item>
              
              <md-list-item href="/eft/">
                <i class="material-icons">contact_support</i>
                <p>EFT</p>
              </md-list-item>

              <md-list-item href="/profil/">
                <i class="material-icons">face</i>
                <p>Votre sophrologue</p>
              </md-list-item>

              <md-list-item href="/podcast-rencontre-avec-soi/">
                <i class="material-icons">mic_none</i>
                <p>Podcast</p>
              </md-list-item>

              <md-list-item
                href="https://www.facebook.com/clemence.sophrologue/"
                target="_blank"
                rel="noopener"
              >
                <i class="fab icon2-facebook-official"></i>
                <p class="hidden-lg">Page Facebook</p>
                <md-tooltip md-direction="bottom">Suivez-moi sur Facebook</md-tooltip>
              </md-list-item>
              <md-list-item
                href="https://www.linkedin.com/in/clémence-bauche-3ba825135"
                target="_blank"
                rel="noopener"
              >
                <i class="fab icon2-linkedin-squared"></i>
                <p class="hidden-lg">Profil Linkedin</p>
                <md-tooltip md-direction="bottom">Voir mon profil Linkedin</md-tooltip>
              </md-list-item>
              <md-list-item
                href="https://www.instagram.com/clem_sophrologue_atypique/"
                target="_blank"
                rel="noopener"
              >
                <i class="fab icon2-instagram"></i>
                <p class="hidden-lg">Instagram</p>
                <md-tooltip md-direction="bottom">Voir mon profil Instagram</md-tooltip>
              </md-list-item>
              <md-list-item
                href="https://www.pinterest.ca/clemencesophrologue/boards/"
                target="_blank"
                rel="noopener"
              >
                <i class="fab icon2-pinterest-squared"></i>
                <p class="hidden-lg">Pinterest</p>
                <md-tooltip md-direction="bottom">Voir mon profil Pinterest</md-tooltip>
              </md-list-item>
            </md-list>
          </div>
        </div>
      </div>
    </div>
  </md-toolbar>
</template>

<script>
let resizeTimeout;
function resizeThrottler(actualResizeHandler) {
  // ignore resize events as long as an actualResizeHandler execution is in the queue
  if (!resizeTimeout) {
    resizeTimeout = setTimeout(() => {
      resizeTimeout = null;
      actualResizeHandler();

      // The actualResizeHandler will execute at a rate of 15fps
    }, 66);
  }
}

import MobileMenu from "@/layout/MobileMenu";
export default {
  components: {
    MobileMenu,
  },
  props: {
    type: {
      type: String,
      default: "white",
      validator(value) {
        return [
          "white",
          "default",
          "primary",
          "danger",
          "success",
          "warning",
          "info",
        ].includes(value);
      },
    },
    colorOnScroll: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      extraNavClasses: "",
      toggledClass: false,
    };
  },
  computed: {},
  methods: {
    bodyClick() {
      let bodyClick = document.getElementById("bodyClick");

      if (bodyClick === null) {
        let body = document.querySelector("body");
        let elem = document.createElement("div");
        elem.setAttribute("id", "bodyClick");
        body.appendChild(elem);

        let bodyClick = document.getElementById("bodyClick");
        bodyClick.addEventListener("click", this.toggleNavbarMobile);
      } else {
        bodyClick.remove();
      }
    },
    toggleNavbarMobile() {
      this.NavbarStore.showNavbar = !this.NavbarStore.showNavbar;
      this.toggledClass = !this.toggledClass;
      this.bodyClick();
    },
    handleScroll() {
      let scrollValue =
        document.body.scrollTop || document.documentElement.scrollTop;
      let navbarColor = document.getElementById("toolbar");
      this.currentScrollValue = scrollValue;
      if (document.documentElement.scrollTop > 60) {
        this.extraNavClasses = `md-${this.type}`;
        navbarColor.classList.remove("md-transparent");
        navbarColor.classList.add("md-white");
      } else {
        if (this.extraNavClasses) {
          this.extraNavClasses = "";
          navbarColor.classList.add("md-transparent");
          navbarColor.classList.remove("md-white");
        }
      }
    },
    scrollListener() {
      resizeThrottler(this.handleScroll);
    },
    scrollToElement() {
      let element_id = document.getElementById("downloadSection");
      if (element_id) {
        element_id.scrollIntoView({ block: "end", behavior: "smooth" });
      }
    },
  },
  mounted() {
    document.addEventListener("scroll", this.scrollListener);
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.scrollListener);
  },
};
</script>
